import React, {useCallback, useRef} from "react";
import Layout from "../layouts/en";
import { scrollElementIntoView } from "../components/helper";
import { Col, Container, Row } from "reactstrap";
import adobe from "../assets/icons/icons8-adobe-xd-color.svg";
import invision from "../assets/icons/invision.svg";
import zeplin from "../assets/icons/zeplin.svg";
import advantage1 from "../assets/icons/emojis/technologin.png";
import advantage2 from "../assets/icons/emojis/rakete.png";
import advantage3 from "../assets/icons/emojis/zahnrad.png";
import slider1 from "../images/screenshot-kickass-by-laura-philipp.png";
import slider2 from "../images/screenshot-my-source-nutrition.png";
import slider3 from "../images/screenshot-baubranche-jobs-de.png";
import step1 from "../images/photo-1565728744382-61accd4aa148-compressor.jpeg";
import step2 from "../images/photo-1552664688-cf412ec27db2-compressor.jpeg";
import step3 from "../images/photo-1545235617-9465d2a55698-compressor.jpeg";
import step4 from "../images/photo-1531538606174-0f90ff5dce83-compressor.jpeg";
import header from "../images/luke-chesser-vCF5sB7QecM-unsplash-compressor.jpg";
import SectionContact from "../components/sections/sectioncontact";
import Collapsible from "react-collapsible";
import {throttle} from "lodash";

function Uxuidesign(props) {
  const scrollTop = useRef(0);
  if (typeof document !== `undefined`) {
    document.documentElement.classList.remove('nav-open');
  }
  const resizeListener = useCallback(() => {
    document.querySelectorAll('.slider-img').forEach(el => {
      let height = el.offsetWidth * 0.62;
      el.style.minHeight = height + 'px';
    });
  }, []);
  const scrollListener = useCallback(() => {
    let st = window.pageYOffset || document.documentElement.scrollTop;
    if (document.getElementById('page-slider')) {
      let scroll = document.getElementById('page-slider').getBoundingClientRect();

      if (scroll.top <= window.innerHeight && scroll.top >= -scroll.height) {
        let windowHeight = window.innerHeight;
        let windowWidth = window.innerWidth;
        let elementWidth = scroll.width;
        let elementHeight = scroll.height;
        if (st > scrollTop.current){
          scroll = scroll.bottom;
        } else {
          scroll = scroll.bottom;
        }
        let elementWidthTotal = 0;
        if (windowWidth >= 992) {
          elementWidthTotal = (elementWidth - 716) * 3 + 2 * 24;
        } else if (windowWidth >= 576) {
          elementWidthTotal = (elementWidth - 258) * 3 + 2 * 24;
        } else {
          elementWidthTotal = (elementWidth - 80) * 3 + 2 * 16;
        }
        let difference = elementWidthTotal - elementWidth;
        let heightTotal =  windowHeight + elementHeight;
        let percentage = scroll / heightTotal;
        if (percentage >= 0 && percentage <= 1) {
          let reversePercentage = 1 - percentage;
          if (st > scrollTop.current){
            document.getElementById('page-slider').style.transform = 'translateX(-' + (reversePercentage * difference) + 'px)';
          } else {
            document.getElementById('page-slider').style.transform = 'translateX(-' + (difference - (percentage * difference)) + 'px)';
          }
        }
      }
    }
    scrollTop.current = st <= 0 ? 0 : st;
  }, []);
  const loadListener = useCallback(() => {
    document.querySelectorAll('.slider-img').forEach(el => {
      let height = el.offsetWidth * 0.62;
      el.style.minHeight = height + 'px';
    });
  }, []);
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    if (typeof window !== `undefined` && window.location.hash !== '') {
      if (document.getElementById(window.location.hash.replace('#', '')) !== null) {
        setTimeout(() => {
          scrollElementIntoView(document.getElementById(window.location.hash.replace('#', '')));
        }, 500);
      }
    }
    document.querySelectorAll('.slider-img').forEach(el => {
      let height = el.offsetWidth * 0.62;
      el.style.minHeight = height + 'px';
    });
    window.addEventListener('resize', throttle(resizeListener, 200));
    window.addEventListener('load', loadListener);
    window.addEventListener('scroll', throttle(scrollListener, 20));
    return () => {
      window.removeEventListener('resize', resizeListener);
      window.removeEventListener('scroll', scrollListener);
      window.removeEventListener('load', loadListener);
    }
  }, []);

  return (
    <Layout classNameChild="uxuidesign" location={props.location} title="UX design agency from Karlsruhe | newww.agency" description="As UX design from Karlsruhe, we develop unique user experiences. Experts in, brand design, app design and UX / UI design." keywords="Online-Shop, Conception, UX/UI-Design, Development, Shopware, Magento, Shopify, WooCommerce, " robots="index, follow, notranslate">
      <div className="section section-dark section-header" id="uxuidesign">
        <Container>
          <Row>
            <Col lg="6" md="12" sm="12" xs="12">
              <div className="text-wrapper">
                <h3 className="header-subheadline">Unique user experiences</h3>
                <h1 className="header-headline">As a UX design agency, we develop unique user experiences.</h1>
                <a className="create-contact dark" href="mailto:hey@newww.agency?subject=Contact request">Make contact</a>
              </div>
            </Col>
            <Col lg="6" md="12" sm="12" xs="12">
              <div className="img-wrapper" style={{backgroundImage: `url(${header})`}} />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="section section-tripple" id="advantages">
        <Container>
          <h2 className="headline">Your experts for digital products and user experiences.</h2>
          <p className="description">Our services focus on the development of product strategies that meet all usability and design requirements. We create innovative designs for mobile apps, online shops and websites.</p>
          <Row>
            <Col lg="4" md="6" sm="6" xs="12">
              <div className="box">
                <div className="box-img" style={{backgroundImage: `url(${advantage1})`}}/>
                <h3 className="box-title">Custom-fit designs</h3>
                <p className="box-description">We develop tailor-made user and brand experiences that generate enthusiasm among users and are characterized by their simplicity.</p>
              </div>
            </Col>
            <Col lg="4" md="6" sm="6" xs="12">
              <div className="box">
                <div className="box-img" style={{backgroundImage: `url(${advantage2})`}}/>
                <h3 className="box-title">Higher conversions</h3>
                <p className="box-description">We help you to translate your ideas and ideas into market success and develop solutions that increase conversions.</p>
              </div>
            </Col>
            <Col lg="4" md="6" sm="6" xs="12">
              <div className="box">
                <div className="box-img" style={{backgroundImage: `url(${advantage3})`}}/>
                <h3 className="box-title">Optimized resources</h3>
                <p className="box-description">For us, the focus is not on the design itself, but on the end result. Only in this way can existing resources be used optimally.</p>
              </div>
            </Col>
          </Row>
          <a className="create-contact" href="mailto:hey@newww.agency?subject=Contact request">Make contact</a>
        </Container>
      </div>
      <div className="section section-dark section-slider" id="slider">
        <Container>
          <h2 className="headline">We love our customers and work closely with them.</h2>
          <p className="description">In each of our projects, we rely on close cooperation with our customers. The focus is always on the future user, whom we want to offer a unique experience.</p>
          <div className="slider" id="page-slider">
            <div className="slider-img" style={{backgroundImage: `url(${slider1})`}}/>
            <div className="slider-img" style={{backgroundImage: `url(${slider2})`}}/>
            <div className="slider-img" style={{backgroundImage: `url(${slider3})`}}/>
          </div>
          <a className="create-contact" href="mailto:hey@newww.agency?subject=Contact request">Make contact</a>
        </Container>
      </div>
      <div className="section section-knowledge" id="knowledge">
        <Container>
          <Row>
            <Col lg="6" md="12" sm="12" xs="12">
              <div className="text-wrapper">
                <h3 className="knowledge-subheadline">Did you know that …</h3>
                <h2 className="knowledge-headline">50% of developers work on problems that could have been avoided.</h2>
                <p className="knowledge-paragraph">
                  Good preparation is one of the essential factors for successful product development. According to studies, almost every second developer deals with problems that could have been avoided with real-time UX tests. Proper planning in advance can save time for everyone.
                </p>
                <a className="create-contact" href="mailto:hey@newww.agency?subject=Contact request">Make contact</a>
              </div>
            </Col>
            <Col lg="6" md="12" sm="12" xs="12">
              <div className="img-wrapper" />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="section section-dark section-line" id="line">
        <Container>
          <h2 className="headline">We rely on flexible processes and work hand in hand.</h2>
          <p className="description">We use agile and efficient processes in all our projects. We cover all areas from branding to UX / UI design to graphic design that are needed for a successful online presence.</p>
          <Row>
            <Col lg="3" md="6" sm="6" xs="12">
              <div className="box">
                <div className="box-img" style={{backgroundImage: `url(${step1})`}}/>
                <div className="box-line">
                  <div className="line" />
                  <div className="circle" />
                </div>
                <h4 className="box-title">Research & Analysis</h4>
                <p className="box-description">Before we start with the actual design, we deal intensively with the project. The focus is always on the future user.</p>
              </div>
            </Col>
            <Col lg="3" md="6" sm="6" xs="12">
              <div className="box">
                <div className="box-img" style={{backgroundImage: `url(${step2})`}}/>
                <div className="box-line">
                  <div className="line" />
                  <div className="circle" />
                </div>
                <h4 className="box-title">Concept development</h4>
                <p className="box-description">After research and analysis, the concept phase begins. Here the rough is correctly determined and defined, which then serves as a reference for the further steps.</p>
              </div>
            </Col>
            <Col lg="3" md="6" sm="6" xs="12">
              <div className="box">
                <div className="box-img" style={{backgroundImage: `url(${step3})`}}/>
                <div className="box-line">
                  <div className="line" />
                  <div className="circle" />
                </div>
                <h4 className="box-title">Design & Testing</h4>
                <p className="box-description">We create detailed prototypes and test them intensively. The result is a sophisticated screen design that leaves no questions unanswered and provides precise specifications.</p>
              </div>
            </Col>
            <Col lg="3" md="6" sm="6" xs="12">
              <div className="box">
                <div className="box-img" style={{backgroundImage: `url(${step4})`}}/>
                <div className="box-line">
                  <div className="line" />
                  <div className="circle" />
                </div>
                <h4 className="box-title">Handoff & QA</h4>
                <p className="box-description">As a full-service agency, we know that the development of digital products is always an interaction of all actors. That is why we work closely with the developers.</p>
              </div>
            </Col>
          </Row>
          <a className="create-contact" href="mailto:hey@newww.agency?subject=Contact request">Make contact</a>
        </Container>
      </div>
      <div className="section section-tripple" id="technologies">
        <Container>
          <h2 className="headline">We work with the best technology there is.</h2>
          <p className="description">Whether Adobe XD, InVision or Zeplin, we work with the best technologies available and are geared towards our customers. Our goal is always to develop digital products that trigger a wow.</p>
          <Row>
            <Col lg="4" md="6" sm="6" xs="12">
              <div className="box">
                <div className="box-img" style={{backgroundImage: `url(${adobe})`}}/>
                <h3 className="box-title">Adobe XD</h3>
                <p className="box-description">In addition to Sketch and Figma, we mainly rely on Adobe XD for screen design, which can also be used to quickly build detailed prototypes.</p>
              </div>
            </Col>
            <Col lg="4" md="6" sm="6" xs="12">
              <div className="box">
                <div className="box-img" style={{backgroundImage: `url(${invision})`}}/>
                <h3 className="box-title">InVision</h3>
                <p className="box-description">We mainly use InVision for collaboration and presentation. In this way, detailed feedback can be given in the prototypes.</p>
              </div>
            </Col>
            <Col lg="4" md="6" sm="6" xs="12">
              <div className="box">
                <div className="box-img" style={{backgroundImage: `url(${zeplin})`}}/>
                <h3 className="box-title">Zeplin</h3>
                <p className="box-description">Zeplin specializes in the cooperation of UX designers and developers and transfers all essential requirements and specifications.</p>
              </div>
            </Col>
          </Row>
          <a className="create-contact" href="mailto:hey@newww.agency?subject=Contact request">Make contact</a>
        </Container>
      </div>
      <div className="section section-dark section-faq" id="faq">
        <Container>
          <h2 className="headline">Frequently asked questions (FAQ)</h2>
          <Row>
            <Col lg="12" className="faq-wrapper">
              <ul>
                <li>
                  <Collapsible trigger="How long does a UX design project take?">
                    <p>
                      The duration of a UX design project depends on a number of different factors. As a rule, we work with our customers in so-called design sprints, which provide a framework and enable flexible and agile cooperation.
                    </p>
                  </Collapsible>
                </li>
                <li>
                  <Collapsible trigger="How much does a UX design project cost?">
                    <p>
                      In all our projects, we follow our customer's budget and make it our task to get the most out of the means available. Our usual hourly rate is on average € 80.00 plus VAT. The costs therefore always depend on the complexity and scope of the project.
                    </p>
                  </Collapsible>
                </li>
                <li>
                  <Collapsible trigger="How does a UX design project work?">
                    <p>
                      We start each of our projects with intensive research in which we take a close look at your project. The next step is to find a concept that serves as the basis for the subsequent screen design. As soon as this is in place, we deal with the handoff and quality control.
                    </p>
                  </Collapsible>
                </li>
                <li>
                  <Collapsible trigger="Which technologies are used?">
                    <p>
                        We start each of our projects with intensive research in which we take a close look at your project. The next step is to find a concept that serves as the basis for the subsequent screen design. As soon as this is in place, we deal with the handoff and quality control.
                    </p>
                  </Collapsible>
                </li>
                <li>
                  <Collapsible trigger="Where does newww.agency offer its services?">
                    <p>
                      Our headquarters are in Karlsruhe. Our customers, on the other hand, are at home all over the world. We look after our customers across all locations and have established processes that enable flexible and smooth cooperation.
                    </p>
                  </Collapsible>
                </li>
              </ul>
            </Col>
          </Row>
          <a className="create-contact" href="mailto:hey@newww.agency?subject=Contact request">Make contact</a>
        </Container>
      </div>
      <SectionContact lang='en' />
    </Layout>
  );
}

export default Uxuidesign;
